import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../components/Layout/Layout"
import RoundedLinkButton from "../components/Buttons/RoundedLinkButton"
import Navbar from "../components/Navbar/Navbar"

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <Layout title={t("SEO_PAGE_404_TAB_TITLE")}>
      <Navbar />
      <section className="h-full flex flex-col justify-center items-center">
        <div className="flex justify-center  flex-col">
          <p className="text-4xl font-headline mb-6">
            {t("PAGE_404_LABEL_404")}
          </p>
          <p className="text-4xl font-headline mb-6 capitalize-first">
            {t("PAGE_404_TEXT_FIRST_LINE")}
          </p>
          <p className="capitalize-first">{t("PAGE_404_TEXT_SECOND_LINE")}</p>
          <p className="capitalize-first mb-6">
            {t("PAGE_404_TEXT_THIRD_LINE")}
          </p>
          <RoundedLinkButton
            url="/"
            label={t("PAGE_404_BUTTON_LABEL_HOMEPAGE")}
            variant="primary"
            size="medium"
          />
        </div>
      </section>
    </Layout>
  )
}

export default NotFound

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
